import { Container, Text, Spacer, Button, Card, Grid } from "@nextui-org/react";
import Link from "next/link";
import { scrollToDownloads } from "./downloads";
import { useCurrentDownload } from "./downloads/list";
import useTranslation, { TranslatedMessage } from "./i18n";
import { DownloadIcon } from "./icons/downloadIcon";
import { EllipsisIcon } from "./icons/ellipsisIcon";

export default function Screen({ dmca }: { dmca: boolean }) {
  const t = useTranslation();
  const download = useCurrentDownload();
  const iconColor = dmca ? "gray700" : "primarySolidContrast";
  const downloadButton = download ? (
    <Button
      auto
      disabled={dmca}
      size="xl"
      onPress={() => {
        ym(91506751, "reachGoal", "downloadNow");
        if (download.scrollToDownloads) {
          scrollToDownloads();
        }
      }}
      css={{
        marginRight: "0",
        "@xs": {
          marginRight: "var(--nextui-space-lg)",
        },
        filter: "drop-shadow(0px 0px 8px rgba(0,0,0,0.5))",
      }}
    >
      <>
        <div
          style={{
            display: "flex",
            marginRight: "var(--nextui-space-11)",
          }}
        >
          {download.logo({
            size: "36px",
            color: `var(--nextui-colors-${iconColor})`,
            background: "var(--nextui-colors-primary)",
          })}
        </div>
        <TranslatedMessage
          id="screen.buttons.download-platform"
          values={{ platform: download.title }}
        />
      </>
    </Button>
  ) : undefined;
  return (
    <Container
      css={{
        w: "100%",
        mw: "unset",
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",
        justifyContent: "center",
        alignItems: "center",
        height: "calc(90vh - var(--nextui--navbarHeight))",
      }}
    >
      <Text
        h1
        css={{
          color: "$white",
          fontSize: "3rem",
          textAlign: "center",
          overflowWrap: "anywhere",
          textShadow: "0px 0px 8px rgba(0,0,0,0.65)",
          "@xs": { fontSize: "8vw" },
        }}
      >
        <TranslatedMessage id="screen.title" />
      </Text>
      <Text
        h2
        css={{
          color: "$white",
          textShadow: "0px 0px 6px rgba(0,0,0,0.75)",
          textAlign: "center",
          "@sm": { fontSize: "2vw" },
        }}
      >
        <TranslatedMessage id="screen.subtitle" />
      </Text>
      <Spacer css={{ height: "$8" }} />
      <Container
        xs
        css={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        {download ? (
          <>
            {download.scrollToDownloads || dmca ? (
              downloadButton
            ) : (
              <Link href={download.link} target="_blank">
                {downloadButton}
              </Link>
            )}
            <Button
              auto
              disabled={dmca}
              size="xl"
              title={t.formatMessage("screen.buttons.all")}
              onPress={scrollToDownloads}
              css={{
                marginTop: "$10",
                "@xs": { marginTop: "$0" },
                filter: "drop-shadow(0px 0px 8px rgba(0,0,0,0.5))",
              }}
            >
              <EllipsisIcon
                size="32px"
                props={{
                  fill: `var(--nextui-colors-${iconColor})`,
                }}
              />
              <Text
                css={{
                  display: "flex",
                  color: "inherit",
                  fontSize: "inherit",
                  paddingLeft: "var(--nextui-space-8)",
                  "@xs": {
                    display: "none",
                  },
                }}
              >
                <TranslatedMessage id="screen.buttons.all" />
              </Text>
            </Button>
          </>
        ) : (
          <Button
            auto
            disabled={dmca}
            size="xl"
            css={{
              w: "100%",
              "@xs": {
                w: "auto",
                marginRight: "var(--nextui-space-lg)",
              },
              filter: "drop-shadow(0px 0px 8px rgba(0,0,0,0.5))",
            }}
            onPress={scrollToDownloads}
          >
            <>
              <div
                style={{
                  display: "flex",
                  marginRight: "24px",
                }}
              >
                <DownloadIcon
                  size="32px"
                  props={{
                    fill: `var(--nextui-colors-${iconColor})`,
                  }}
                  style={{ marginRight: "8px" }}
                />
              </div>
              <TranslatedMessage id="screen.buttons.download" />
            </>
          </Button>
        )}
      </Container>
    </Container>
  );
}
