import { Link } from "@nextui-org/react";
import useTranslation, { TranslatedMessage } from "../i18n";
import useDownloadFromListGoal from "./goal";
import { DownloadContent } from "./list";
import { ChromeLogo } from "../icons/chromeLogo";

export default function ChromeOsDownload(): DownloadContent {
  const t = useTranslation();
  const link = `https://docs.llaun.ch/${
    t.intl.locale === "ru" ? "" : "en/"
  }faq/chromeos`;
  const goal = useDownloadFromListGoal(undefined);
  return {
    title: t.formatMessage("downloads.chromeos.title"),
    subtitle: undefined,
    link: link,
    logo: ({ size, color }) => (
      <ChromeLogo
        size={size}
        props={{
          fill: color,
        }}
      />
    ),
    helpContent: () => (
      <TranslatedMessage
        id="downloads.chromeos.text"
        values={{
          read_the_docs: (
            <Link href={link} css={{ display: "inline-flex" }} onClick={goal}>
              <TranslatedMessage id="downloads.chromeos.read_the_docs" />
            </Link>
          ),
        }}
      />
    ),
  };
}
