import { keyframes, styled } from "@nextui-org/react";
import Image, { StaticImageData } from "next/image";
import {
  ClassAttributes,
  CSSProperties,
  HTMLAttributes,
  useState,
  VideoHTMLAttributes,
} from "react";
import { PlayIcon } from "../../icons/playIcon";

const opacityAnimation = keyframes({
  "0%": { opacity: 0.25 },
  "100%": { opacity: 1 },
});

type Props = {
  image: StaticImageData;
  videoName: string;
  currentlyPlaying: string | undefined;
  currentlyPlayingCallback: (videoName: string | undefined) => void;
};

const LayerDiv = styled("div", {
  width: "100%",
  aspectRatio: "32 / 9",
});

function Layer({
  style,
  children,
}: {
  style: CSSProperties;
  children: JSX.Element;
}) {
  return (
    <LayerDiv
      style={{
        position: "absolute",
        ...style,
      }}
    >
      {children}
    </LayerDiv>
  );
}

function VideoElement({
  videoName,
  ...props
}: { videoName: string } & VideoHTMLAttributes<HTMLVideoElement>) {
  return (
    <video style={{ width: "100%", aspectRatio: "32 / 9" }} {...props}>
      <source
        src={`/${videoName}_av1.mp4`}
        type="video/mp4; codecs=av01.0.05M.08"
      />
      <source src={`/${videoName}_hevc.mp4`} type="video/mp4; codecs=hvc1" />
      <source src={`/${videoName}.webm`} type="video/webm; codecs=vp9" />
      <source src={`/${videoName}.mp4`} type="video/mp4" />
    </video>
  );
}

export function VideoPlayer({
  image,
  videoName,
  currentlyPlaying: currentVideoName,
  currentlyPlayingCallback: currentVideoNameCallback,
}: Props) {
  const isPlayerStarted = videoName == currentVideoName; //[isPlayerStarted, setPlayerStarted] = useState(false);
  const [isVideoLoaded, setVideoLoaded] = useState(false);

  const isVideoLoadingState = isPlayerStarted && !isVideoLoaded;

  return (
    <LayerDiv
      style={{ position: "relative", cursor: "pointer" }}
      tabIndex={0}
      role="button"
      onClick={() => {
        if (!isPlayerStarted) {
          currentVideoNameCallback(videoName);
        }
      }}
    >
      {isPlayerStarted ? (
        <VideoElement
          videoName={videoName}
          autoPlay={true}
          loop={true}
          controls
          onCanPlayThrough={() => setVideoLoaded(true)}
        />
      ) : (
        <>
          <Layer
            style={{
              display: "flex",
              justifyContent: "start",
              padding: "var(--nextui-space-md)",
            }}
          >
            <PlayIcon
              size="3rem"
              props={{
                fill: "var(--nextui-colors-primarySolidContrast)",
              }}
              style={{
                filter: "drop-shadow(0px 0px 10px rgb(0 0 0 / 0.4))",
                opacity: isVideoLoadingState
                  ? undefined
                  : isPlayerStarted
                  ? 0
                  : 1,
                transition: "opacity 200ms",
                animation: isVideoLoadingState
                  ? `${opacityAnimation} infinite 1s alternate-reverse`
                  : undefined,
              }}
            />
          </Layer>
          <Image alt="" src={image} style={{ width: "100%", height: "auto" }} />
        </>
      )}
    </LayerDiv>
  );
}
