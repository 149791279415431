import { Badge, Collapse, Container, Spacer, Text } from "@nextui-org/react";
import { useContext, useEffect, useState } from "react";
import { Platform } from "./platform";
import { PlatformContext } from "./platform/context";
import { useSortedDownloadList, isCurrent } from "./downloads/list";
import UnsupportedCard from "./downloads/unsupportedCard";
import { TranslatedMessage } from "./i18n";

export default function Downloads() {
  const platform = useContext(PlatformContext);
  const downloads = useSortedDownloadList();
  const [allExpanded, setAllExpanded] = useState(true);
  useEffect(() => {
    setAllExpanded(false);
  }, []);
  return (
    <Container md css={{ px: "$0" }}>
      <Spacer id="downloads" css={{ h: "var(--nextui--navbarHeight)" }} />
      <Text h1 css={{ px: "$8" }}>
        <TranslatedMessage id="downloads.title" />
      </Text>
      {platform === Platform.MOBILE ? <UnsupportedCard /> : undefined}
      <Spacer />
      <Container sm css={{ px: "$0" }}>
        <Collapse.Group
          splitted
          accordion={false}
          animated={false}
          css={{ "@xs": { padding: "$0" } }}
        >
          {downloads.map((d) => {
            const currentPlatform = isCurrent(d, platform);
            return (
              <Collapse
                key={d.title}
                title={
                  <div style={{ display: "flex", gap: "8px" }}>
                    <Text h4>{d.title}</Text>
                    {currentPlatform ? (
                      <Badge color="primary">
                        <TranslatedMessage id="downloads.for-you" />
                      </Badge>
                    ) : undefined}
                  </div>
                }
                subtitle={d.subtitle}
                contentLeft={d.logo({
                  size: "48px",
                  color: "var(--nextui-colors-foreground)",
                  background: "var(--nextui-colors-background)",
                })}
                expanded={allExpanded || currentPlatform}
              >
                {d.helpContent()}
              </Collapse>
            );
          })}
        </Collapse.Group>
      </Container>
    </Container>
  );
}

export function scrollToDownloads() {
  return document
    .querySelector("#downloads")
    ?.scrollIntoView({ behavior: "smooth" });
}
