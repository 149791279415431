import { Text, Container, Spacer, Button, useTheme } from "@nextui-org/react";
import Link from "next/link";
import { useContext } from "react";
import useTranslation, { TranslatedMessage } from "../i18n";
import { FlatHubLogo } from "../icons/flathubLogo";
import { Platform } from "../platform";
import { LinkPrefixContext } from "../prefix";
import useDownloadFromListGoal from "./goal";
import { DownloadContent } from "./list";

const linkBase = "/flathub";

export default function FlathubDownload(): DownloadContent {
  const link = useContext(LinkPrefixContext) + linkBase;
  const invertIcon = !!useTheme().isDark;
  const t = useTranslation();
  const goal = useDownloadFromListGoal(Platform.LINUX);
  return {
    title: "Flathub",
    subtitle: t.formatMessage("downloads.flathub.for-supported"),
    link,
    logo: ({ size, color, background }) => (
      <FlatHubLogo
        size={size}
        props={{
          fill: invertIcon ? color : background,
        }}
        backgroundColor={invertIcon ? background : color}
      />
    ),
    helpContent: () => (
      <Container>
        <Text>
          <TranslatedMessage id="downloads.flathub.open-flathub-and-install" />
        </Text>
        <Spacer />
        <Link href={link} target="_blank">
          <Button size="xl" onClick={goal}>
            <FlatHubLogo
              size="32px"
              backgroundColor="var(--nextui-colors-primary)"
              props={{
                fill: "var(--nextui-colors-primarySolidContrast)",
              }}
              style={{ marginRight: "8px" }}
            />
            <TranslatedMessage id="downloads.flathub.open-flathub" />
          </Button>
        </Link>
        <Spacer />
        <TranslatedMessage id="downloads.flathub.note" />
      </Container>
    ),
  };
}
