import { useContext } from "react";
import { Platform } from "../platform";
import { PlatformContext } from "../platform/context";
import FlathubDownload from "./flathub";
import ManualJavaDownload from "./java";
import MacOsDownload from "./macos";
import UbuntuDownload from "./ubuntu";
import WindowsDownload from "./windows";
import ChromeOsDownload from "./chromeos";

export type DownloadContent = {
  logo: (props: {
    size: string;
    color: string;
    background: string;
    margin?: string | undefined;
  }) => JSX.Element;
  title: string;
  subtitle?: string | undefined;
  helpContent: () => JSX.Element;
  platform?: Platform | undefined;
  link: string;
  scrollToDownloads?: boolean | undefined;
};

export function useDownloadList() {
  const downloads: DownloadContent[] = [
    WindowsDownload(),
    MacOsDownload(),
    // AurDownload(),
    FlathubDownload(),
    UbuntuDownload(),
    ChromeOsDownload(),
    ManualJavaDownload(),
  ];
  return downloads;
}

export function useCurrentDownload(): DownloadContent | undefined {
  const platform = useContext(PlatformContext);
  const filtered = useDownloadList().filter((d) => isCurrent(d, platform));
  return filtered.length == 1 ? filtered[0] : undefined;
}

export function useSortedDownloadList() {
  const platform = useContext(PlatformContext);
  return useDownloadList().sort((a, b) => {
    const ca = isCurrent(a, platform);
    const cb = isCurrent(b, platform);
    if (ca) {
      if (cb) {
        return 0;
      }
      return -1;
    } else {
      if (cb) {
        return 1;
      }
      return 0;
    }
  });
}

export function isCurrent(
  d: DownloadContent,
  platform: Platform | undefined
): boolean {
  return d.platform !== undefined && d.platform === platform;
}
