import NextLink from "next/link";
import { Text, Spacer, Button, Link } from "@nextui-org/react";
import { AppleLogo } from "../icons/appleLogo";
import { Platform } from "../platform";
import { DownloadContent } from "./list";
import { useContext } from "react";
import { LinkPrefixContext } from "../prefix";
import useDownloadFromListGoal from "./goal";
import useTranslation, { TranslatedMessage } from "../i18n";

const linkBase = "/macos";

export default function MacOsDownload(): DownloadContent {
  const link = useContext(LinkPrefixContext) + linkBase;
  const t = useTranslation();
  const goal = useDownloadFromListGoal(Platform.MACOS);
  return {
    platform: Platform.MACOS,
    title: "macOS",
    subtitle: t.formatMessage("downloads.macos.intel-or-rosetta"),
    link,
    logo: ({ size, color }) => (
      <AppleLogo
        size={size}
        props={{
          fill: color,
        }}
      />
    ),
    helpContent: () => (
      <ol>
        <li>
          <Text>
            <TranslatedMessage id="downloads.macos.click-below" />
          </Text>
          <Spacer />
          <NextLink href={link} target="_blank">
            <Button size="xl" onClick={goal}>
              <AppleLogo
                size="32px"
                props={{
                  fill: "var(--nextui-colors-primarySolidContrast)",
                }}
                style={{ marginRight: "8px" }}
              />
              <TranslatedMessage id="downloads.macos.download-image" />
            </Button>
          </NextLink>
          <Spacer />
        </li>
        <li>
          <TranslatedMessage id="downloads.macos.mount-it-and-install" />{" "}
          <Link
            isExternal
            target="_blank"
            css={{ display: "inline-flex" }}
            href="https://appstorrent.ru/200-mistakes.html"
          >
            <TranslatedMessage id="downloads.macos.common-pitfalls" />
          </Link>
        </li>
      </ol>
    ),
  };
}
