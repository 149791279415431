import { CSSProperties } from "react";

// https://github.com/flathub/website/blob/efd87f64845c17a05ff6623e05d0724970f28706/frontend/public/img/logo/flathub-logo-toolbar.svg
// Apache License 2.0
export const FlatHubLogo = ({
  size,
  props,
  backgroundColor,
  style,
}: {
  size: string;
  props: { [key: string]: string };
  backgroundColor: string;
  style?: CSSProperties;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 34.745999 34.745997"
    width={size}
    height={size}
    style={style}
    {...props}
  >
    <defs id="defs24" />
    <g id="layer1" transform="translate(-4.1943224,-354.54445)">
      <g id="g4539" transform="translate(0,2.7663275)">
        <g
          style={{ strokeWidth: 0.777106 }}
          id="g10674"
          transform="matrix(0.24323469,0,0,0.24464027,-186.64462,153.79857)"
        >
          <g
            id="g10652"
            transform="matrix(0.50221552,0,0,0.50636711,429.63205,432.90461)"
            style={{ strokeWidth: 0.58729 }}
          >
            <rect
              transform="matrix(0.84019328,0.54228706,-0.84019328,0.54228706,0,0)"
              ry="0"
              rx="0"
              y="279.42584"
              x="1289.9076"
              height="79.110413"
              width="79.110413"
              id="rect10644"
              style={{
                fill: backgroundColor,
              }}
            />
            <path
              id="path10648"
              d="m 915.46811,824.92983 -5e-5,68.99997 -66.46803,42.90055 5e-5,-68.99997 z"
              style={{
                fill: backgroundColor,
              }}
            />
            <path
              style={{
                fill: backgroundColor,
              }}
              d="m 783.00003,824.92983 5e-5,68.99997 66.46803,42.90055 -5e-5,-68.99997 z"
              id="path10650"
            />
            <rect
              //style="color:#000000;clip-rule:nonzero;display:inline;overflow:visible;visibility:visible;opacity:1;isolation:auto;mix-blend-mode:normal;color-interpolation:sRGB;color-interpolation-filters:linearRGB;solid-color:#000000;solid-opacity:1;fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:#204a87;stroke-width:2.4609;stroke-linecap:square;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1;marker:none;marker-start:none;marker-mid:none;marker-end:none;paint-order:normal;color-rendering:auto;image-rendering:auto;shape-rendering:auto;text-rendering:auto;enable-background:accumulate"
              style={{
                fill: "#ffffff",
                stroke: backgroundColor,
                strokeWidth: "2.4609",
              }}
              id="rect10646"
              width="79.110413"
              height="79.110413"
              x="1226.2882"
              y="215.8064"
              rx="0"
              ry="0"
              transform="matrix(0.84019328,0.54228706,-0.84019328,0.54228706,0,0)"
            />
          </g>
          <g
            id="g10662"
            transform="matrix(0.50221552,0,0,0.50636711,463.01368,454.62849)"
            style={{ strokeWidth: "0.58729" }}
          >
            <rect
              style={{
                fill: "#ffffff",
                stroke: backgroundColor,
                strokeWidth: "2.4609",
              }}
              id="rect10654"
              width="79.110413"
              height="79.110413"
              x="1289.9076"
              y="279.42584"
              rx="0"
              ry="0"
              transform="matrix(0.84019328,0.54228706,-0.84019328,0.54228706,0,0)"
            />
            <rect
              transform="matrix(0.84019328,0.54228706,-0.84019328,0.54228706,0,0)"
              ry="0"
              rx="0"
              y="215.8064"
              x="1226.2882"
              height="79.110413"
              width="79.110413"
              id="rect10656"
              style={{
                fill: "#ffffff",
                stroke: backgroundColor,
                strokeWidth: "2.4609",
              }}
            />
            <path
              style={{
                fill: backgroundColor,
                stroke: backgroundColor,
                strokeWidth: "2.34916",
              }}
              d="m 915.46811,824.92983 -5e-5,68.99997 -66.46803,42.90055 5e-5,-68.99997 z"
              id="path10658"
            />
            <path
              id="path10660"
              d="m 783.00003,826.54445 5e-5,68.99997 66.46803,42.90055 -5e-5,-68.99997 z"
              style={{
                fill: "#ffffff",
                stroke: backgroundColor,
                strokeWidth: "2.34916",
              }}
            />
          </g>
          <g
            transform="matrix(0.50221552,0,0,0.50636711,396.25042,454.62849)"
            id="g10672"
            style={{ strokeWidth: "0.58729" }}
          >
            <rect
              transform="matrix(0.84019328,0.54228706,-0.84019328,0.54228706,0,0)"
              ry="0"
              rx="0"
              y="279.42584"
              x="1289.9076"
              height="79.110413"
              width="79.110413"
              id="rect10664"
              style={{
                fill: backgroundColor,
              }}
            />
            <rect
              style={{
                fill: "#ffffff",
                stroke: backgroundColor,
                strokeWidth: "2.4609",
              }}
              id="rect10666"
              width="79.110413"
              height="79.110413"
              x="1226.2882"
              y="215.8064"
              rx="0"
              ry="0"
              transform="matrix(0.84019328,0.54228706,-0.84019328,0.54228706,0,0)"
            />
            <path
              id="path10668"
              d="m 915.46811,824.92983 -5e-5,68.99997 -66.46803,42.90055 5e-5,-68.99997 z"
              style={{
                fill: backgroundColor,
              }}
            />
            <path
              style={{
                fill: "#ffffff",
                stroke: backgroundColor,
                strokeWidth: "2.34916",
              }}
              d="m 783.00003,824.92983 5e-5,68.99997 66.46803,42.90055 -5e-5,-68.99997 z"
              id="path10670"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
