import { Card, Container, Grid, Text } from "@nextui-org/react";
import { TranslatedMessage } from "../i18n";
import { LaptopIcon } from "../icons/laptopIcon";

export default function UnsupportedCard() {
  return (
    <Container sm>
      <Card>
        <Card.Body>
          <Grid.Container gap={2}>
            <Grid xs={12} sm={2} alignItems="center" justify="center">
              <LaptopIcon
                size="64px"
                props={{
                  fill: "var(--nextui-colors-text)",
                }}
              />
            </Grid>
            <Grid xs={12} sm={10} direction="column">
              <Text h4>
                <TranslatedMessage id="downloads.unsupported.only-desktop" />
              </Text>
              <Text>
                <TranslatedMessage id="downloads.unsupported.explanation" />
              </Text>
            </Grid>
          </Grid.Container>
        </Card.Body>
      </Card>
    </Container>
  );
}
