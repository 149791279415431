import { Platform } from ".";

export default function getPlatform(
  userAgent: string | undefined
): Platform | undefined {
  if (!userAgent) {
    return;
  }
  if (/(?:android|ios|ipad)/i.test(userAgent)) {
    return Platform.MOBILE;
  }
  if (/windows/i.test(userAgent)) {
    return Platform.WINDOWS;
  }
  if (/(?:mac|osx)/i.test(userAgent)) {
    return Platform.MACOS;
  }
  if (/ubuntu/i.test(userAgent)) {
    return Platform.UBUNTU;
  }
  // if (/linux/i.test(userAgent)) {
  //   return Platform.LINUX;
  // }
}
