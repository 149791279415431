import {
  Collapse,
  CollapseProps,
  Container,
  Link,
  Spacer,
  Text,
} from "@nextui-org/react";
import NextLink from "next/link";
import { useContext, useEffect, useState } from "react";
import useTranslation, {
  isTranslationId,
  TranslatedMessage,
  TranslationId,
} from "./i18n";
import { LinkPrefixContext } from "./prefix";
import { isPostTL } from "./tllegacy";

type FaqEntry = {
  question: TranslationId | string;
  answer: JSX.Element;
  props?: Partial<CollapseProps>;
};

type GifProps = {
  id: string;
  src: {
    type: "iframe" | "video";
    path: string;
  };
  title: TranslationId | string;
  width: number;
  height: number;
  lang?: string;
};

export default function Faq() {
  const t = useTranslation();
  const linkPrefix = useContext(LinkPrefixContext);
  const [visibleGifs, setVisibleGifs] = useState<Set<string>>(new Set());

  const Gif = ({ id, src, title, width, height, lang }: GifProps) => {
    const titleContent = isTranslationId(title)
      ? t.formatMessage(title)
      : title;
    return (
      <div
        lang={lang}
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        {visibleGifs.has(id) ? (
          src.type === "video" ? (
            <video
              title={titleContent}
              loop={true}
              autoPlay={true}
              muted={true}
              style={{
                width: `500px`,
                maxWidth: "100%",
                aspectRatio: `${width} / ${height}`,
              }}
            >
              <source
                src={`${src.path}_av1.mp4`}
                type="video/mp4; codecs=av01.0.05M.08"
              />
              <source
                src={`${src.path}_hevc.mp4`}
                type="video/mp4; codecs=hvc1"
              />
              <source src={`${src.path}.webm`} type="video/webm; codecs=vp9" />
              <source src={`${src.path}.mp4`} type="video/mp4" />
            </video>
          ) : (
            <iframe
              title={titleContent}
              src={src.path}
              loading="lazy"
              style={{
                width: "100%",
                maxWidth: `${width}px`,
                aspectRatio: `${width} / ${height}`,
                border: "none",
                opacity: "0",
                transition: `opacity 200ms`,
              }}
              onLoad={(e) =>
                ((e.target as HTMLIFrameElement).style.opacity = "1")
              }
            />
          )
        ) : (
          <></>
        )}
      </div>
    );
  };

  const setGif = (id: string, visible: boolean) => {
    if (visible) {
      visibleGifs.add(id);
    } else {
      visibleGifs.delete(id);
    }
    setVisibleGifs(new Set(visibleGifs));
  };

  let bezkoshtovno = <></>;

  if (t.intl.locale === "ru") {
    bezkoshtovno = (
      <Gif
        id="free"
        src={{
          type: "video",
          path: "/besplatno-ryan-gosling",
        }}
        title="Бесплатно (мем с Райаном Гослингом)"
        width={640}
        height={546}
        lang="ru"
      />
    );
  } else if (t.intl.locale === "uk") {
    bezkoshtovno = (
      <Gif
        id="free"
        src={{
          type: "video",
          path: "/bezkoshtovno",
        }}
        title="Безкоштовно (мем з Папічем)"
        width={640}
        height={472}
        lang="uk"
      />
    );
  }

  const entries: (FaqEntry | undefined)[] = [
    isPostTL
      ? undefined
      : {
          question: "faq.ex-tl-legacy",
          answer: (
            <Text>
              <TranslatedMessage id="faq.ex-tl-legacy.it-was-renamed" />{" "}
              <NextLink href="/ex-tl-legacy">
                <TranslatedMessage id="faq.ex-tl-legacy.it-was-renamed.about" />
              </NextLink>
            </Text>
          ),
        },
    {
      question: "faq.is-it-free",
      answer: (
        <>
          {bezkoshtovno}
          <Text b>
            <TranslatedMessage id="faq.is-it-free.of-course" />
          </Text>
          <Text>
            <TranslatedMessage id="faq.is-it-free.no-paid-features-ever" />
          </Text>
        </>
      ),
      props: {
        onChange: (_e, _index, visible) => setGif("free", !!visible),
      },
    },
    {
      question: "faq.is-there-any-malware",
      answer: (
        <>
          <Text b>
            <TranslatedMessage id="faq.is-there-any-malware.of-course-not" />
          </Text>
          <Text>
            <TranslatedMessage
              id="faq.is-there-any-malware.source-code"
              values={{
                source_code_is_available: (
                  <Link
                    href={`${linkPrefix}/source`}
                    target="_blank"
                    css={{ display: "inline-flex" }}
                  >
                    <TranslatedMessage id="faq.is-there-any-malware.source-code.available-for-you" />
                  </Link>
                ),
              }}
            />
          </Text>
        </>
      ),
    },
    // {
    //   question: "faq.is-it-a-piracy",
    //   answer: (
    //     <>
    //       <Gif
    //         id="pirate"
    //         src={{
    //           type: "iframe",
    //           path: "https://giphy.com/embed/jLa9e5pDtZb1Hxfg6n",
    //         }}
    //         title="faq.is-it-free.gif"
    //         width={388}
    //         height={480}
    //       />
    //       <Text b>
    //         <TranslatedMessage id="faq.is-it-a-piracy.well-yeah" />
    //       </Text>
    //       <Text>
    //         <TranslatedMessage id="faq.is-it-a-piracy.cracked-history" />
    //       </Text>
    //       <Text>
    //         <TranslatedMessage id="faq.is-it-a-piracy.there-are-limits" />
    //       </Text>
    //       <Text>
    //         <TranslatedMessage id="faq.is-it-a-piracy.dont-forget-to-feel-bad" />
    //       </Text>
    //     </>
    //   ),
    //   props: {
    //     onChange: (_e, _index, visible) => setGif("pirate", !!visible),
    //   },
    // },
    t.intl.locale !== "ru"
      ? undefined
      : {
          question: "Можно ли устанавливать читы?",
          answer: (
            <>
              <Text b>
                Да, лаунчер позволяет игрокам запускать любые версии игры без
                ограничений.
              </Text>
              <Text>
                Мы не рекомендуем пользоваться читами там, где это запрещено.
              </Text>
            </>
          ),
        },
    {
      question: "faq.can-i-install-mods",
      answer: (
        <>
          <Text b>
            <TranslatedMessage id="faq.can-i-install-mods.sure" />
          </Text>
          <Text>
            <TranslatedMessage id="faq.can-i-install-mods.there-are-forge-and-fabric" />
          </Text>
        </>
      ),
    },
    {
      question: "faq.can-i-change-my-skin",
      answer: (
        <>
          <Text b>
            <TranslatedMessage id="faq.can-i-change-my-skin.sure" />
          </Text>
          <Text>
            <TranslatedMessage
              id="faq.can-i-change-my-skin.use-ely-by"
              values={{
                Ely_by: (
                  <Link
                    href="https://ely.by"
                    target="_blank"
                    css={{ display: "inline-flex" }}
                  >
                    Ely.by
                  </Link>
                ),
              }}
            />
          </Text>
        </>
      ),
    },
    {
      question: "faq.portable-build",
      answer: (
        <>
          <Text b>
            <TranslatedMessage id="faq.portable-build.it-is-supported" />
          </Text>
          <Text>
            <TranslatedMessage
              id="faq.portable-build.download-it"
              values={{
                here: (
                  <Link
                    href={`${linkPrefix}/portable`}
                    target="_blank"
                    css={{ display: "inline-flex" }}
                  >
                    <TranslatedMessage id="faq.portable-build.download-it.here" />
                  </Link>
                ),
              }}
            />
          </Text>
        </>
      ),
    },
    {
      question: "faq.i-have-a-problem",
      answer: (
        <Text>
          <TranslatedMessage id="faq.i-have-a-problem.contact-support-below" />
        </Text>
      ),
    },
  ];

  const [hasExternalSelection, setHasExternalSelection] =
    useState<boolean>(true);

  useEffect(() => {
    if (hasNoExternalSelection()) {
      setHasExternalSelection(false);
    }
  }, []);

  return (
    <Container md css={{ px: "$0" }}>
      <Spacer id="faq" css={{ h: "var(--nextui--navbarHeight)" }} />
      <Text h1 css={{ px: "$8" }}>
        <TranslatedMessage id="faq" />
      </Text>
      <Container sm css={{ px: "$1" }}>
        <Collapse.Group accordion={false} animated={false}>
          {entries.map((entry) => {
            if (!entry) {
              return undefined;
            }
            const question = isTranslationId(entry.question)
              ? t.formatMessage(entry.question)
              : entry.question;
            return (
              <Collapse
                key={question}
                itemScope
                itemType="https://schema.org/Question"
                title={
                  <Text h3 itemProp="name">
                    {question}
                  </Text>
                }
                {...entry.props}
                expanded={hasExternalSelection}
              >
                <Container
                  itemProp="suggestedAnswer acceptedAnswer"
                  itemScope
                  itemType="https://schema.org/Answer"
                >
                  <div itemProp="text">{entry.answer}</div>
                </Container>
              </Collapse>
            );
          })}
        </Collapse.Group>
      </Container>
    </Container>
  );
}

function hasNoExternalSelection() {
  if (
    !("fragmentDirective" in document) ||
    typeof performance === "undefined"
  ) {
    return true;
  }
  // https://web.dev/text-fragments/#obtaining-text-fragments-for-analytics-purposes
  const textSelectionUrl = performance
    .getEntries()
    .find((e) => (e as unknown as PerfEntry).type === "navigate")?.name;
  return (
    !textSelectionUrl || !new URL(textSelectionUrl).hash.startsWith("#:~:text=")
  );
}

type PerfEntry = {
  type: "navigate" | string;
};
