import { Text, Spacer, Button } from "@nextui-org/react";
import Link from "next/link";
import { useContext } from "react";
import { TranslatedMessage } from "../i18n";
import { WindowsLogo } from "../icons/windowsLogo";
import { Platform } from "../platform";
import { LinkPrefixContext } from "../prefix";
import useDownloadFromListGoal from "./goal";
import { DownloadContent } from "./list";

const linkBase = "/installer";

export default function WindowsDownload(): DownloadContent {
  const link = useContext(LinkPrefixContext) + linkBase;
  const goal = useDownloadFromListGoal(Platform.WINDOWS);
  return {
    platform: Platform.WINDOWS,
    title: "Windows",
    link,
    logo: ({ size, color }) => (
      <WindowsLogo
        size={size}
        props={{
          fill: color,
        }}
      />
    ),
    helpContent: () => (
      <ol>
        <li>
          <Text>
            <TranslatedMessage id="downloads.windows.click-below" />
          </Text>
          <Spacer />
          <Link href={link} target="_blank">
            <Button size="xl" onClick={goal}>
              <WindowsLogo
                size="32px"
                props={{
                  fill: "var(--nextui-colors-primarySolidContrast)",
                }}
                style={{ marginRight: "24px" }}
              />
              <TranslatedMessage id="downloads.windows.download-installer" />
            </Button>
          </Link>
          <Spacer css={{ h: "$1" }} />
        </li>
        <li>
          <TranslatedMessage id="downloads.windows.start-installer" />
        </li>
      </ol>
    ),
  };
}
