import { Card, Container, Grid, Link, Text } from "@nextui-org/react";
import { TranslatedMessage } from "./i18n";
import { GavelIcon } from "./icons/gavelIcon";

export function DmcaCard() {
  return (
    <Container xs>
      <Card>
        <Card.Body>
          <Grid.Container gap={2}>
            <Grid xs={12} sm={2} alignItems="center" justify="center">
              <GavelIcon
                size="64px"
                props={{
                  fill: "var(--nextui-colors-text)",
                }}
              />
            </Grid>
            <Grid xs={12} sm={10} direction="column">
              <Text h4>
                <TranslatedMessage id="dmca.downloads.title" />
              </Text>
              <Text>
                <TranslatedMessage id="dmca.downloads.text" />{" "}
                <Link href="https://minecraft.net" target="_blank">
                  <TranslatedMessage id="dmca.downloads.buy" />
                </Link>
              </Text>
            </Grid>
          </Grid.Container>
        </Card.Body>
      </Card>
    </Container>
  );
}
