import React, { useState, useEffect, useCallback, useRef } from "react";
import { Container, Spacer, Text, useTheme } from "@nextui-org/react";
import Image, { StaticImageData } from "next/image";
import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";

import lightMain from "../images/light-main.png";
import lightSettings from "../images/light-settings.png";
import lightAccounts from "../images/light-accounts.png";
import darkMain from "../images/dark-main.png";
import darkSettings from "../images/dark-settings.png";
import darkAccounts from "../images/dark-accounts.png";
import enLightMain from "../images/en-light-main.png";
import enLightSettings from "../images/en-light-settings.png";
import enLightAccounts from "../images/en-light-accounts.png";
import enDarkMain from "../images/en-dark-main.png";
import enDarkSettings from "../images/en-dark-settings.png";
import enDarkAccounts from "../images/en-dark-accounts.png";
import Link from "next/link";
import useTranslation, { TranslatedMessage } from "./i18n";

export default function ScreenshotsCarousel() {
  const useRu = useTranslation().intl.locale === "ru";
  const { isDark: useDark } = useTheme();
  return (
    <Container md>
      <Spacer css={{ h: "var(--nextui--navbarHeight)" }} />
      <Text h1>
        <TranslatedMessage id="screenshots.title" />
      </Text>
      <Spacer />
      <EmblaCarousel isDark={!!useDark} isRu={useRu} />
      <Spacer />
    </Container>
  );
}

function EmblaCarousel({ isDark, isRu }: { isDark: boolean; isRu: boolean }) {
  const t = useTranslation();
  const alt = t.formatMessage("screenshots.alt");

  const screenshots = [
    isDark ? (isRu ? darkMain : enDarkMain) : isRu ? lightMain : enLightMain,
    isDark
      ? isRu
        ? darkSettings
        : enDarkSettings
      : isRu
      ? lightSettings
      : enLightSettings,
    isDark
      ? isRu
        ? darkAccounts
        : enDarkAccounts
      : isRu
      ? lightAccounts
      : enLightAccounts,
  ];

  const autoplay = useRef(
    Autoplay({
      delay: 3000,
      stopOnInteraction: true,
      rootNode: (emblaRoot) => emblaRoot.parentElement,
    })
  );

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [mainViewportRef, embla] = useEmblaCarousel({ skipSnaps: false }, [
    autoplay.current,
  ]);
  const [thumbViewportRef, emblaThumbs] = useEmblaCarousel({
    containScroll: "keepSnaps",
    dragFree: true,
  });

  const onThumbClick = useCallback(
    (index: number) => {
      if (!embla || !emblaThumbs) return;
      if (emblaThumbs.clickAllowed()) embla.scrollTo(index);
      autoplay.current.stop();
    },
    [embla, emblaThumbs]
  );

  const onSelect = useCallback(() => {
    if (!embla || !emblaThumbs) return;
    setSelectedIndex(embla.selectedScrollSnap());
    emblaThumbs.scrollTo(embla.selectedScrollSnap());
  }, [embla, emblaThumbs, setSelectedIndex]);

  useEffect(() => {
    if (!embla) return;
    onSelect();
    embla.on("select", onSelect);
  }, [embla, onSelect]);

  return (
    <>
      <div className="embla">
        <div className="embla__viewport" ref={mainViewportRef}>
          <div className="embla__container">
            {screenshots.map((image, index) => (
              <div className="embla__slide" key={index}>
                <div className="embla__slide__inner">
                  <Link href={image.src} locale={false} target="_blank">
                    <Image
                      className="embla__slide__img"
                      quality={90}
                      src={image}
                      alt={alt}
                      style={{
                        objectFit: "cover",
                      }}
                    />
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="embla embla--thumb">
        <div className="embla__viewport" ref={thumbViewportRef}>
          <div className="embla__container embla__container--thumb">
            {screenshots.map((image, index) => (
              <Thumb
                onClick={() => onThumbClick(index)}
                selected={index === selectedIndex}
                image={image}
                key={index}
                alt={alt}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

function Thumb({
  selected,
  onClick,
  image,
  alt,
}: {
  selected: boolean;
  onClick: () => void;
  image: StaticImageData;
  alt: string;
}) {
  return (
    <div
      className={`embla__slide embla__slide--thumb ${
        selected ? "is-selected" : ""
      }`}
    >
      <button
        onClick={onClick}
        className="embla__slide__inner embla__slide__inner--thumb"
        type="button"
      >
        <Image
          className="embla__slide__thumbnail"
          src={image}
          alt={alt}
          style={{
            objectFit: "cover",
          }}
        />
      </button>
    </div>
  );
}
