import { Card, Container, Grid, Spacer, Text } from "@nextui-org/react";
import NextLink from "next/link";
import { useContext } from "react";
import useTranslation, { TranslatedMessage } from "./i18n";
import { DiscordLogo } from "./icons/discordLogo";
import { VkLogo } from "./icons/vkLogo";
import { LinkPrefixContext } from "./prefix";

export default function Support() {
  const t = useTranslation();
  const linkPrefix = useContext(LinkPrefixContext);

  return (
    <Container md css={{ px: "$0" }}>
      <Spacer id="support" css={{ h: "var(--nextui--navbarHeight)" }} />
      <Text h1 css={{ px: "$8" }}>
        <TranslatedMessage id="support" />
      </Text>
      <Spacer />
      <Spacer />
      <Container sm>
        <Card>
          <Card.Body>
            <Text>
              <TranslatedMessage id="support.you-can-contact-us" />
            </Text>
            <Text>
              <TranslatedMessage id="support.we-ll-try-to-help" />
            </Text>
          </Card.Body>
        </Card>
        <Grid.Container gap={4} css={{ px: "$3" }} justify="center">
          <Grid xs={12} sm={6}>
            <NextLink
              href={`${linkPrefix}/discord/${
                t.intl.locale === "ru" ? "ru" : "intl"
              }`}
              target="_blank"
              style={{ display: "flex", flexGrow: 1, flexDirection: "column" }}
            >
              <Card isPressable css={{ flexGrow: 1 }}>
                <Card.Body>
                  <Grid.Container gap={2} css={{ flexGrow: 1 }}>
                    <Grid xs={12} sm={3} justify="center">
                      <DiscordLogo
                        size="96px"
                        props={{ fill: "var(--nextui-colors-text)" }}
                      />
                    </Grid>
                    <Grid xs={12} sm={6} justify="center" direction="column">
                      <Text h4 css={{ mb: "$0" }}>
                        Discord
                      </Text>
                    </Grid>
                  </Grid.Container>
                </Card.Body>
              </Card>
            </NextLink>
          </Grid>
          {t.intl.locale === "ru" ? (
            <Grid xs={12} sm={6}>
              <NextLink
                href={`${linkPrefix}/vk`}
                target="_blank"
                style={{ display: "flex", flexGrow: 1 }}
              >
                <Card isPressable>
                  <Card.Body>
                    <Grid.Container gap={2}>
                      <Grid xs={12} sm={3} justify="center">
                        <VkLogo
                          size="96px"
                          props={{ fill: "var(--nextui-colors-text)" }}
                        />
                      </Grid>
                      <Grid xs={12} sm={6} justify="center" direction="column">
                        <Text h4 css={{ mb: "$0" }}>
                          ВКонтакте
                        </Text>
                      </Grid>
                    </Grid.Container>
                  </Card.Body>
                </Card>
              </NextLink>
            </Grid>
          ) : undefined}
        </Grid.Container>
      </Container>
    </Container>
  );
}
