import { Card, Container, Grid, Text } from "@nextui-org/react";
import useTranslation, { TranslationId } from "./i18n";
import { Stats } from "./stats";

export default function NumberCards({ stats }: { stats: Stats }) {
  return (
    <Container md>
      <Grid.Container gap={2} justify="center">
        <NumberCard num={stats.today} id="numberCards.card.players-today" />
        <NumberCard num={stats.online} id="numberCards.card.players-online" />
        <NumberCard
          num={stats.last_month_unique}
          id="numberCards.card.players-monthly"
        />
      </Grid.Container>
    </Container>
  );
}

const suffixPaths: (TranslationId | null)[] = [
  null,
  "numberCards.card.magnutute.thousand",
  "numberCards.card.magnutute.million",
  "numberCards.card.magnutute.billion",
];

function formatK(count: number) {
  let magnutude = 0;
  while (count >= 1000 && magnutude < suffixPaths.length - 1) {
    count /= 1000;
    magnutude++;
  }
  return {
    count,
    path: suffixPaths[magnutude],
  };
}

function NumberCard({ num, id }: { num: number; id: TranslationId }) {
  const t = useTranslation();
  if (num <= 0) {
    return <></>;
  }
  const segments = splitSegments(t.formatMessage(id, { count: num }), num);
  const { count, path } = formatK(num);
  const countFixed = count.toFixed(0);
  return (
    <Grid xs={12} sm={3}>
      <Card>
        <Card.Body>
          {segments.top ? <Text>{segments.top}</Text> : <></>}
          <Text weight="bold" size="$5xl" className="notranslate">
            {path === null
              ? countFixed
              : t.formatMessage(path, { count: countFixed })}
          </Text>
          {segments.bottom ? <Text>{segments.bottom}</Text> : <></>}
        </Card.Body>
      </Card>
    </Grid>
  );
}

function splitSegments(
  unformatted: string,
  count: number
): {
  top: string | undefined;
  bottom: string | undefined;
} {
  const c = String(count);
  let s: string[];
  if (unformatted.includes(c)) {
    s = unformatted.split(String(count));
  } else {
    s = ["", unformatted];
  }
  return {
    top: s[0]?.trim() ?? undefined,
    bottom: s[1]?.trim() ?? undefined,
  };
}
