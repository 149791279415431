import { Text, Link, Spacer, Button } from "@nextui-org/react";
import { useContext, useState } from "react";
import useTranslation, { TranslatedMessage } from "../../i18n";
import { UbuntuLogo } from "../../icons/ubuntuLogo";
import { Platform } from "../../platform";
import { LinkPrefixContext } from "../../prefix";
import useDownloadFromListGoal from "../goal";
import { DownloadContent } from "../list";
import { VideoPlayer } from "./video";

import ubuntu1Image from "./ubuntu_1.png";
import ubuntu2Image from "./ubuntu_2.png";
import ubuntu3Image from "./ubuntu_3.png";

const linkBase = "/ubuntu";

export default function UbuntuDownload(): DownloadContent {
  const link = useContext(LinkPrefixContext) + linkBase;
  const t = useTranslation();
  const goal = useDownloadFromListGoal(Platform.UBUNTU);
  const [currentlyPlaying, currentlyPlayingCallback] = useState<
    string | undefined
  >();
  return {
    platform: Platform.UBUNTU,
    scrollToDownloads: true,
    title: "Ubuntu",
    subtitle: t.formatMessage("downloads.ubuntu.debian-based"),
    link,
    logo: ({ size, color }) => (
      <UbuntuLogo
        size={size}
        props={{
          fill: color,
        }}
      />
    ),
    helpContent: () => (
      <>
        <ol>
          <li key="download">
            <Text>
              <TranslatedMessage id="downloads.ubuntu.v2.click-below" />
            </Text>
            <Spacer />
            <Link href={link} target="_blank">
              <Button size="xl" onClick={goal}>
                <UbuntuLogo
                  size="32px"
                  props={{
                    fill: "var(--nextui-colors-primarySolidContrast)",
                  }}
                  style={{ marginRight: "24px" }}
                />
                <TranslatedMessage id="downloads.ubuntu.v2.download-deb" />
              </Button>
            </Link>
            <Spacer css={{ h: "$1" }} />
          </li>
          <li key="open">
            <Text>
              <TranslatedMessage id="downloads.ubuntu.v2.open-with-software-install" />
            </Text>
            <Spacer />
            <VideoPlayer
              videoName="ubuntu_1"
              image={ubuntu1Image}
              currentlyPlaying={currentlyPlaying}
              currentlyPlayingCallback={currentlyPlayingCallback}
            />
            <Spacer css={{ h: "$1" }} />
          </li>
          <li key="install">
            <Text>
              <TranslatedMessage id="downloads.ubuntu.v2.install-as-usual" />
            </Text>
            <Spacer />
            <VideoPlayer
              videoName="ubuntu_2"
              image={ubuntu2Image}
              currentlyPlaying={currentlyPlaying}
              currentlyPlayingCallback={currentlyPlayingCallback}
            />
            <Spacer css={{ h: "$1" }} />
          </li>
          <li key="launch">
            <Text>
              <TranslatedMessage id="downloads.ubuntu.v2.find-and-launch" />
            </Text>
            <Spacer />
            <VideoPlayer
              videoName="ubuntu_3"
              image={ubuntu3Image}
              currentlyPlaying={currentlyPlaying}
              currentlyPlayingCallback={currentlyPlayingCallback}
            />
            <Spacer css={{ h: "$1" }} />
          </li>
        </ol>
      </>
    ),
  };
}
