import { Link } from "@nextui-org/react";
import { useContext } from "react";
import useTranslation, { TranslatedMessage } from "../i18n";
import { JavaLogo } from "../icons/javaLogo";
import { LinkPrefixContext } from "../prefix";
import useDownloadFromListGoal from "./goal";
import { DownloadContent } from "./list";

const linkBase = "/jar";

export default function ManualJavaDownload(): DownloadContent {
  const link = useContext(LinkPrefixContext) + linkBase;
  const t = useTranslation();
  const goal = useDownloadFromListGoal(undefined);
  return {
    title: t.formatMessage("downloads.manual.title"),
    subtitle: undefined,
    link: link,
    logo: ({ size, color }) => (
      <JavaLogo
        size={size}
        props={{
          fill: color,
        }}
      />
    ),
    helpContent: () => (
      <ol>
        <li>
          <TranslatedMessage
            id="downloads.manual.download-and-install-java"
            values={{
              adoptium: (
                <Link
                  href="https://adoptium.net/"
                  target="_blank"
                  isExternal={true}
                  css={{ display: "inline-flex" }}
                >
                  Adoptium
                </Link>
              ),
            }}
          />
        </li>
        <li>
          <TranslatedMessage
            id="downloads.manual.download-and-launch-jar"
            values={{
              jar: (
                <Link
                  href={link}
                  css={{ display: "inline-flex" }}
                  target="_blank"
                  onClick={goal}
                >
                  <TranslatedMessage id="downloads.manual.jar" />
                </Link>
              ),
            }}
          />
        </li>
      </ol>
    ),
  };
}
