import { useContext, useState } from "react";
import { Platform } from "../platform";
import { PlatformContext } from "../platform/context";

export default function useDownloadFromListGoal(
  platform: Platform | undefined
) {
  const [goalReached, setGoalReached] = useState(false);
  const currentPlatform = useContext(PlatformContext);
  return () => {
    if (
      platform === undefined ||
      (currentPlatform === platform && !goalReached)
    ) {
      ym(91506751, "reachGoal", "downloadFromList");
      setGoalReached(true);
    }
  };
}
