import Image, { StaticImageData } from "next/image";
import { useContext, useEffect, useState } from "react";
import { useTheme } from "@nextui-org/react";
import { PlatformContext } from "./platform/context";
import { Platform } from "./platform";

import plains4K from "../images/plains4K.jpg";
import plains4Kdark from "../images/plains4Kdark.jpg";

const [Z_IMG_ORIG, Z_IMG_DESAT, Z_MIN] = Array.from(Array(10).keys()).map(
  (k) => -k - 1
);

export default function Background() {
  const isMobile = useContext(PlatformContext) === Platform.MOBILE;
  const { isDark: useDark } = useTheme();
  const [selectedImage, setSelectedImage] = useState<
    StaticImageData | undefined
  >(undefined);
  useEffect(() => {
    setSelectedImage(useDark ? plains4Kdark : plains4K);
  }, [useDark]);
  const [scrollProgress, setScrollProgress] = useState(0);
  useEffect(() => {
    const listener = () => {
      setScrollProgress(
        Math.max(Math.min(window.scrollY / window.innerHeight, 1.0), 0.0)
      );
    };
    window.addEventListener("scroll", listener, { passive: true });
    return () => window.removeEventListener("scroll", listener);
  });
  const emptyBackground = scrollProgress >= 1.0;
  return (
    <div
      style={{
        position: "fixed",
        top: "0",
        width: "100%",
        height: "100%",
        maxHeight: "100vh",
        zIndex: Z_MIN,
      }}
    >
      {emptyBackground || !selectedImage ? undefined : (
        <>
          {isMobile ? undefined : (
            <Image
              src={selectedImage}
              quality={100}
              fill
              placeholder="blur"
              alt=""
              style={{
                objectFit: "cover",
                zIndex: Z_IMG_DESAT,
                opacity: `calc(1.0 - ${scrollProgress})`,
                filter: "grayscale(1)",
              }}
            />
          )}
          <Image
            src={selectedImage}
            quality={100}
            fill
            placeholder="blur"
            alt=""
            style={{
              objectFit: "cover",
              zIndex: Z_IMG_ORIG,
              opacity: `calc(1.0 - 2 * ${scrollProgress})`,
            }}
          />
        </>
      )}
    </div>
  );
}
